import React from "react";
import SimilarProfileItem from "./SimilarProfileItem";
import titleIcon from "./images/similar-profiles-title-icon.png";
import Title from "./home-page-logged-in/Title";
import classes from "./SimilarProfilesContainer.module.css";

const SimilarProfilesContainer = ({ similarProfiles }) => {
  return (
    <React.Fragment>
      <Title
        overrideStyles={classes.titleContainer}
        title="Profiles with similar interests"
        icon={titleIcon}
      />
      <div className="row">
        {similarProfiles.map((profile, key) => (
          <SimilarProfileItem
            key={key}
            name={`${profile.firstName} ${profile.lastName}`}
            // TODO: about is missing in the current response.Check later if it's available
            description={profile.about}
            profileImage={profile.profileImage}
            username={profile.loginName}
            userId={profile.userId}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default SimilarProfilesContainer;
