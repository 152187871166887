import React, { useState } from "react";
import SingleArticleItem from "./SingleArticleItem";
import Title from "./Title";
import titleIcon from "../images/popular-articles-title-icon.png";
import classes from "./PopularArticles.module.css";

const PopularArticles = () => {
  const [articlesList, setArticlesList] = useState([
    {
      id: 167,
      title: "The Impact of Climate Change on Species Diversity",
      content:
        "This article presents a comprehensive overview of the current state of research in...",
      author: "Jenny Wilson",
    },
    {
      id: 168,
      title: "The Dynamics of Climate Change",
      content:
        "This article presents a comprehensive overview of the current state of research in...",
      author: "Cody Fisher",
    },
    {
      id: 169,
      title: "The Role of Microbes in Human Health",
      content:
        "This article presents a comprehensive overview of the current state of research in...",
      author: "Robert Fox",
    },
    {
      id: 170,
      title: "The Role of Pollution in Biodiversity Loss",
      content:
        "This article presents a comprehensive overview of the current state of research in...",
      author: "Jenny Wilson",
    },
    {
      id: 163,
      title: "The Impact of Climate Change on Species Diversity",
      content:
        "This article presents a comprehensive overview of the current state of research in...",
      author: "Jenny Wilson",
    },
    {
      id: 164,
      title: "The Dynamics of Climate Change",
      content:
        "This article presents a comprehensive overview of the current state of research in...",
      author: "Cody Fisher",
    },
    {
      id: 165,
      title: "The Role of Microbes in Human Health",
      content:
        "This article presents a comprehensive overview of the current state of research in...",
      author: "Robert Fox",
    },
    {
      id: 166,
      title: "The Role of Pollution in Biodiversity Loss",
      content:
        "This article presents a comprehensive overview of the current state of research in...",
      author: "Jenny Wilson",
    },
  ]);

  return (
    <React.Fragment>
      <Title
        overrideStyles={classes.titleContainer}
        title="popular"
        icon={titleIcon}
      />
      <div className="row">
        {articlesList.map((item, key) => (
          <SingleArticleItem
            key={key}
            id={item.id}
            title={item.title}
            content={item.content}
            author={item.author}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default PopularArticles;
