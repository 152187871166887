import React, { useContext, useEffect, useState } from "react";
import classes from "./OnboardingCreateProfile.module.css";
import OnboardingCreateProfileInput from "../components/OnboardingCreateProfileInput.js";
import OnboardingCreateProfileSelect from "../components/OnboardingCreateProfileSelect.js";
import uploadImageIcon from "./images/add_photo.png";
import waveImg from "./images/wave.png";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  useActionData,
  useNavigate,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import { BeatLoader } from "react-spinners";
import OnboardingCreateProfileTextarea from "../components/OnboardingCreateProfileTextarea";
import countyData from "../countryList.json";
import { userContext } from "../components/LoggedInBaseLayout";

export default function OnboardingCreateProfile() {
  const countries = countyData.countries;
  const firstName = new URLSearchParams(window.location.search).get("fname");
  const lastName = new URLSearchParams(window.location.search).get("lname");
  const submit = useSubmit();
  const navigate = useNavigate();
  const actionData = useActionData();
  const navigation = useNavigation();
  const [imgSrc, setImgSrc] = useState(null);
  const user = useContext(userContext);

  const experienceYears = [
    { key: "5+ years", value: "5+ years" },
    { key: "4 years", value: "4 years" },
    { key: "3 years", value: "3 years" },
    { key: "2 years", value: "2 years" },
    { key: "1 year", value: "1 year" },
  ];

  // yup url validation
  const yupUrlRegex =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

  const validationSchema = Yup.object({
    country: Yup.string().required("This field is required!"),
    jobTitle: Yup.string().required("This field is required!"),
    experience: Yup.string().required("This field is required!"),
    description: Yup.string().required("This field is required!"),
    website: Yup.string().matches(yupUrlRegex, "Invalid URL!"),
  });

  const initialValues = {
    country: "",
    jobTitle: "",
    company: "",
    experience: "",
    website: "",
    description: "",
    profileImage: "",
  };

  const onSubmit = (values) => {
    // hide all toast notifications
    toast.dismiss();
    submit(
      { firstName, lastName, loginName: user.loginName, ...values },
      { method: "post", action: "/signup/onboarding/profile" }
    );
  };

  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        navigate("/signup/onboarding/interest");
      } else {
        toast.error(
          actionData.error.response.data.message || "Faild to add user info!"
        );
      }
    }
  }, [actionData]);

  const handleImageUpload = (files, rejectedFiles) => {
    // todo: validate the image and do the below after validation
    const currentFile = files[0];
    const imgFileReader = new FileReader();
    imgFileReader.addEventListener(
      "load",
      () => {
        setImgSrc(imgFileReader.result);
      },
      false
    );
    imgFileReader.readAsDataURL(currentFile);
  };

  return (
    <div className="container customContainer">
      <div className={`${classes.centerDiv}`}>
        <div className={classes.waveAndHello}>
          <div className={classes.waveImgDiv}>
            <img src={waveImg} alt="wave image" className={classes.waveImg} />
          </div>
          <p className={`${classes.helloText}`}>
            Hello {firstName ? firstName : "User"},
          </p>
        </div>
        <p className={`${classes.fewMoreStepsText}`}>
          Just a few more steps to complete your profile
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <div className={`row`}>
              <div className={`col-12 col-md-6`}>
                <OnboardingCreateProfileSelect
                  options={countries}
                  name="country"
                  label="Country"
                />
              </div>
              <div className={`col-12 col-md-6`}>
                <OnboardingCreateProfileInput
                  type="text"
                  name="jobTitle"
                  label="Job Title"
                />
              </div>
            </div>
            <div className={`row`}>
              <div className={`col-12 col-md-6`}>
                <OnboardingCreateProfileInput
                  type="text"
                  name="company"
                  label="Company Name"
                />
              </div>
              <div className={`col-12 col-md-6`}>
                <OnboardingCreateProfileSelect
                  options={experienceYears}
                  name="experience"
                  label="Experience"
                />
              </div>
            </div>
            <div>
              <OnboardingCreateProfileInput
                type="text"
                name="website"
                label="Website"
              />
            </div>
            <div>
              <OnboardingCreateProfileTextarea
                label="About You"
                name="description"
              />
            </div>
            {imgSrc ? (
              <div className={`${classes.uploadedImageParentContainer}`}>
                <div className={`d-flex justify-content-center`}>
                  <img className={`${classes.uploadedImage}`} src={imgSrc} />
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={() => setImgSrc(null)}
                    className={`${classes.imageRemoveButton}`}
                  >
                    remove image
                  </button>
                </div>
              </div>
            ) : (
              <>
                <Dropzone
                  // todo: set maxSize
                  multiple={false}
                  accept="image/*"
                  onDrop={handleImageUpload}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        className={classes.uploadImageBox}
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className={classes.uploadImageInnerBox}>
                          <div className={classes.uploadImageIcon}>
                            <img src={uploadImageIcon} alt="Upload Image" />
                          </div>
                          <p className={classes.uploadImageText}>
                            Upload Image
                          </p>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </>
            )}
            <div className={classes.imageUploadBottomTextDiv}>
              <p className={`${classes.imageUploadBottomText}`}>
                We recommend uploading your real photo as it will help you to be
                identified by your community
              </p>
            </div>
            <button type="submit" className={`${classes.submitButton}`}>
              {navigation.state === "idle" ? (
                <p className={classes.submitButtonText}>next</p>
              ) : (
                <BeatLoader loading size={10} />
              )}
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export const onboardingCreateProfileAction =
  (sendRequest) =>
  async ({ request }) => {
    const formData = await request.formData();
    const {
      firstName,
      lastName,
      loginName,
      country,
      jobTitle,
      company,
      experience,
      website: websiteURL,
      description,
      profileImage,
    } = Object.fromEntries(formData);

    const user = JSON.parse(localStorage.getItem("user"));
    const data = {
      body: {
        userId: user?.userId || null,
        firstName,
        lastName,
        loginName,
        country,
        jobTitle,
        company,
        experience,
        websiteURL,
        description,
        profileImage,
      },
    };

    return sendRequest("post", "/user/addUserInfo", data);
  };
