import React, { useState } from "react";
import CategorySelector from "../CategorySelector";
import TopCategoryItem from "./TopCategoryItem";
import classes from "./TopCategoryListBar.module.css";

const TopCategoryListBar = ({ categories }) => {
  const [showCategorySelector, setShowCategorySelector] = useState(false);

  const [navItemsList, setNavItemsList] = useState([
    "machine learning",
    "enviornment",
    "fashion",
    "health & well-being",
    "pets",
    "religion",
    "tech",
    "data",
  ]);

  return (
    <div className="container customContainer">
      <div className="row justify-content-end">
        <div className="col-12 d-lg-flex d-none justify-content-between">
          {navItemsList.map((item, key) => (
            <TopCategoryItem categoryName={item} key={key} />
          ))}
          <div
            className={`${classes.moreIcon}`}
            onClick={() => setShowCategorySelector(true)}
          >
            <span className="material-icons m-auto">more_horiz</span>
          </div>
        </div>
        <div className="col-auto d-lg-none d-block">
          <div
            className={`${classes.moreIcon}`}
            onClick={() => setShowCategorySelector(true)}
          >
            <span className="material-icons m-auto">more_horiz</span>
          </div>
        </div>
      </div>
      <CategorySelector
        categories={categories}
        show={showCategorySelector}
        onHide={setShowCategorySelector}
      />
    </div>
  );
};

export default TopCategoryListBar;
