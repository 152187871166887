import React from "react";
import classes from "./ComingSoonTag.module.css";

function ComingSoonTag() {
  return (
    <div className={classes.tabContainer}>
      <p className={classes.tagText}>coming soon</p>
    </div>
  );
}

export default ComingSoonTag;
