import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import classes from "./SearchResultItem.module.css";

function SearchResultItem({
  id,
  thumbnail_url,
  title,
  score,
  read_time,
  author,
  saved,
}) {
  const [saveArticle, setSaveArticle] = useState(saved);

  const handleSaveArticle = (e) => {
    setSaveArticle(!saveArticle);
  };

  return (
    <div className={`col-lg-3 col-sm-6 col-12 ${classes.articleContainer}`}>
      <Link
        to={`/article/${id}/${title.split(" ").join("_")}`}
        className="text-decoration-none"
      >
        <div
          className={`${classes.articleImage}`}
          style={{
            backgroundImage: `url(${thumbnail_url})`,
          }}
        ></div>
      </Link>
      <Link
        to={`/article/${id}/${title.split(" ").join("_")}`}
        className="text-decoration-none"
      >
        <p className={`${classes.articleTitle}`}>{title}</p>
      </Link>
      <div
        className={`d-flex justify-content-between ${classes.articleScoreAndReadTimeContainer}`}
      >
        <p className={classes.articleScore}>score {score}</p>
        <div className="d-flex align-items-end">
          <p className={classes.articleReadTime}>{read_time} min read</p>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className={classes.articleAuthorNameContainer}>
          <NavLink to={`/user/${author}`} className="text-decoration-none">
            <p className={`${classes.articleAuthor}`}>by {author}</p>
          </NavLink>
        </div>
        <div className="d-flex">
          <div
            className={`mb-auto ${
              saveArticle ? `${classes.saved}` : `${classes.unSaved}`
            }`}
            onClick={handleSaveArticle}
          >
            <span className={`material-icons-outlined ${classes.unSavedIcon}`}>
              bookmark_border
            </span>
            <span className={`material-icons-outlined ${classes.savedIcon}`}>
              bookmark
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchResultItem;
