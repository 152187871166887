import React from "react";
import classes from "./OnboardingCreateProfileLabel.module.css";

const OnboardingCreateProfileLabel = ({ label, name }) => {
  return (
    <label htmlFor={name} className={`${classes.label}`}>
      {label}
    </label>
  );
};

export default OnboardingCreateProfileLabel;
