import React, { useContext, useEffect, useState } from "react";
import classes from "./ArticleDetails.module.css";
import SingleCommentItem from "../components/SingleCommentItem";
import ArticleScoreShowItem from "../components/ArticleScoreShowItem";
import SingleReviewer from "../components/SingleReviewer";
import VotingModal from "../components/VotingModal";
import { NavLink, useLoaderData, useParams, defer } from "react-router-dom";
import { toast } from "react-toastify";
import PostArticleComment from "../components/PostArticleComment";
import ArticleDetailsSuggestChangesButton from "../components/ArticleDetailsSuggestChangesButton";
import { userContext } from "../components/LoggedInBaseLayout";
import defaultAvatar from "../components/images/avator.png";
import RequestProgressBar from "../components/RequestProgressBar";

export default function ArticleDetails() {
  const [isLoadingArticleDetails, setIsLoadingArticleDetails] = useState(true);
  const [isLoadingArticleScores, setIsLoadingArticleScores] = useState(true);
  const [isLoadingArticleComments, setIsLoadingArticleComments] =
    useState(true);
  const [startProgressBar, setStartProgressBar] = useState(true);
  const [completeProgressBar, setCompleteProgressBar] = useState(false);
  const [showVotingModal, setShowVotingModal] = useState(false);
  const [articleDetails, setArticleDetails] = useState(null);
  const [comments, setComments] = useState([]);
  const { id: articleId } = useParams();
  const [isReviewer, setIsReviewer] = useState(false);
  const user = useContext(userContext);
  const [reviewRequestDetails, setReviewRequestDetails] = useState(null);
  const [articleScores, setArticleScores] = useState({
    accuracyScore: 0,
    completenessScore: 0,
    originalityScore: 0,
    consistencyScore: 0,
    timelinessScore: 0,
    totalScore: 0,
    totalVoteCount: 0,
  });
  const [reviewers, setReviewers] = useState([]);
  const articleLoader = useLoaderData();

  useEffect(() => {
    const {
      articleDetails,
      articleComments,
      articleScores,
      reviewRequestDetails,
    } = articleLoader;

    articleDetails
      .then(({ response }) => {
        setArticleDetails(response.data.body);
        setReviewers(response.data.body.reviewers);
      })
      .catch(({ error }) => {
        toast.error(
          error?.response?.data.message || error || "Faild to load the article!"
        );
      })
      .finally(() => {
        setIsLoadingArticleDetails(false);
      });

    articleComments
      .then(({ response }) => {
        setComments(response.data.body.articleComments);
      })
      .catch(({ error }) => {
        toast.error(
          error?.response?.data.message ||
            error ||
            "Faild to load article comments!"
        );
      })
      .finally(() => {
        setIsLoadingArticleComments(false);
      });

    articleScores
      .then(({ response }) => {
        setArticleScores(response.data.body);
      })
      .catch(({ error }) => {
        toast.error(
          error?.response?.data.message ||
            error ||
            "Faild to load article scores!"
        );
      })
      .finally(() => {
        setIsLoadingArticleScores(false);
      });

    reviewRequestDetails.then(({ response }) => {
      setIsReviewer(response.data.body.isReviewer);
      setReviewRequestDetails(response.data.body.reviewRequestByCurrentUser);
    });
  }, [articleLoader]);

  // use to finish the progress bar
  useEffect(() => {
    if (
      !isLoadingArticleComments &&
      !isLoadingArticleDetails &&
      !isLoadingArticleScores
    ) {
      setCompleteProgressBar(true);
    }
  }, [
    isLoadingArticleComments,
    isLoadingArticleDetails,
    isLoadingArticleScores,
  ]);

  return (
    <>
      <RequestProgressBar
        continuousStart={startProgressBar}
        complete={completeProgressBar}
        hideBackground={true}
      />
      {articleDetails && (
        <div
          className={`container customContainer ${classes.artilceDetailsContainer}`}
          style={{ marginTop: "50px" }}
        >
          <div className="row">
            <div className={`col-xl col-12 ${classes.leftContainer}`}>
              {isReviewer ? (
                <ArticleDetailsSuggestChangesButton
                  reviewRequestDetails={reviewRequestDetails}
                  articleId={articleDetails.article.articleId}
                  userId={user?.userId || null}
                />
              ) : (
                ""
              )}
              <div className="row">
                <div className="col-12">
                  <p className={classes.publishedDate}>
                    Published on {articleDetails.article.createdDate}
                  </p>
                  <h1 className={classes.articleTitle}>
                    {articleDetails.article.title}
                  </h1>
                  <div
                    className={`d-sm-flex d-block justify-content-between ${classes.authorDetailsContainer}`}
                  >
                    <div className="d-flex">
                      <div className={classes.authorImageContainer}>
                        <NavLink
                          to={`/user/${articleDetails.authorDetails?.loginName}?id=${articleDetails.authorDetails?.userId}`}
                          className="text-decoration-none"
                        >
                          <div
                            className={classes.authorImage}
                            style={{
                              backgroundImage: articleDetails.authorDetails
                                .profileImageUrl
                                ? `url(${articleDetails.authorDetails.profileImageUrl})`
                                : `url(${defaultAvatar})`,
                            }}
                          ></div>
                        </NavLink>
                      </div>
                      <div
                        className={`d-flex align-items-center ${classes.authorNameContainer}`}
                      >
                        <NavLink
                          to={`/user/${articleDetails.authorDetails?.loginName}?id=${articleDetails.authorDetails?.userId}`}
                          className="text-decoration-none"
                        >
                          <p className={`m-auto ${classes.authorName}`}>
                            by {articleDetails.authorDetails.name}
                          </p>
                        </NavLink>
                      </div>
                    </div>
                    {/* this div is only visible in screens smaller than 1199px */}
                    {articleDetails.article.isOwner ? (
                      <NavLink
                        to={`/article/${articleDetails.article.articleId}/edit`}
                        className="text-decoration-none"
                      >
                        <button
                          className={`d-xl-none d-block ${classes.editArticleButton}`}
                        >
                          edit article
                        </button>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`${classes.articleImage}`}
                    style={{
                      backgroundImage: `url(https://picsum.photos/1000?random=${articleDetails.article.articleId})`,
                    }}
                  ></div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: articleDetails.article.content,
                    }}
                    className={`${classes.descriptionContainer}`}
                  ></div>
                </div>
              </div>
            </div>
            <div className={`col-xl-auto col-12 ${classes.rightContainer}`}>
              {articleDetails.article.isOwner ? (
                articleDetails.article.status.toLowerCase() !== "published" ? (
                  <NavLink
                    to={`/article/${articleDetails.article.articleId}/edit`}
                    className="text-decoration-none"
                  >
                    <button
                      className={`d-xl-block d-none ${classes.editArticleButton}`}
                    >
                      edit article
                    </button>
                  </NavLink>
                ) : null
              ) : null}
              <div className={`${classes.articleScoreContainer}`}>
                <p className={`${classes.articleScoreTitle}`}>Article Score</p>
                <p className={`${classes.articleScore}`}>
                  {articleScores.totalScore} / 10
                </p>
                <p className={`${classes.articleScoreSideNote}`}>
                  {`(Based on ${articleScores.totalVoteCount} Votes)`}
                </p>
                <div className={`${classes.articleScores}`}>
                  <ArticleScoreShowItem
                    width={`${articleScores.accuracyScore}%`}
                    title="accuracy"
                  />
                  <ArticleScoreShowItem
                    width={`${articleScores.completenessScore}%`}
                    title="completeness"
                  />
                  <ArticleScoreShowItem
                    width={`${articleScores.originalityScore}%`}
                    title="originality"
                  />
                  <ArticleScoreShowItem
                    width={`${articleScores.consistencyScore}%`}
                    title="consistency"
                  />
                  <ArticleScoreShowItem
                    width={`${articleScores.timelinessScore}%`}
                    title="timeliness"
                  />
                  <div className="d-flex justify-content-center">
                    <button
                      onClick={() => setShowVotingModal(true)}
                      className={`${classes.voteButton}`}
                    >
                      vote
                    </button>
                    <VotingModal
                      show={showVotingModal}
                      onHide={() => setShowVotingModal(false)}
                      articleId={articleDetails.article.articleId}
                    />
                  </div>
                </div>
              </div>
              {reviewers.length > 0 && (
                <div className={`${classes.reviewersContainer}`}>
                  <p className={`${classes.reviewersTitle}`}>reviewers</p>
                  <div
                    className={`row justify-content-center ${classes.reviewersProfileContainer}`}
                  >
                    <div className="col-auto">
                      <div className="row">
                        {reviewers.map((reviewer, key) => (
                          <SingleReviewer
                            key={key}
                            userName={reviewer.name}
                            userImage={reviewer.profileImageUrl}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className={`col-xl col-12 ${classes.leftContainer}`}>
              <PostArticleComment
                articleId={articleId}
                setComments={setComments}
              />
              <div className={`${classes.commentsSection}`}>
                <p className={`${classes.commentCount}`}>
                  {comments.length} comments
                </p>
                {comments.map((comment, key) => (
                  <SingleCommentItem
                    key={key}
                    userImage={
                      comment.profileImage
                        ? comment.profileImage
                        : defaultAvatar
                    }
                    fullName={`${comment.firstName} ${comment.lastName}`}
                    userName={comment.loginName}
                    userId={comment.userId}
                    comment={comment.content}
                  />
                ))}
              </div>
            </div>
            <div
              className={`col-xl-auto d-xl-block d-none ${classes.rightContainer}`}
            ></div>
          </div>
        </div>
      )}
    </>
  );
}
// this is a temporary solution to give a nice UX in alpha version
// TODO: remove this function when neccessary
const replaceArticleIdWithDummyId = (articleId) => {
  if (articleId.length < 5) {
    return "ARTIC__db841580-48ae-11ee-9711-f5100d883405";
  } else {
    return articleId;
  }
};

const articleDetailsLoader =
  (sendRequest) =>
  ({ params: { id } }) => {
    const articleId = replaceArticleIdWithDummyId(id);
    let user = JSON.parse(localStorage.getItem("user"));
    const params = { articleId };

    const resolved = (response) => {
      const dateObj = new Date(response.data.body.article.createdDate);
      let monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      let publishedDate = `${dateObj.getDate()} ${
        monthNames[dateObj.getMonth()]
      }, ${dateObj.getFullYear()}`;

      // change the date format of the response
      response.data.body.article.createdDate = publishedDate;

      //check if the current user is the owner of this article
      if (response.data.body.authorDetails.userId == user?.userId) {
        response.data.body.article.isOwner = true;
      } else {
        response.data.body.article.isOwner = false;
      }

      return { status: "ok", response };
    };

    const rejected = (error) => {
      return { status: "error", error };
    };

    return sendRequest(
      "get",
      "/article/findArticleById",
      params,
      resolved,
      rejected
    ).then((resp) => {
      if (resp.status === "error") {
        throw resp;
      } else {
        return resp;
      }
    });
  };

const articleCommentsLoader =
  (sendRequest) =>
  ({ params: { id: articleId } }) => {
    const params = {
      body: {
        articleId,
        parentCommentId: "-1",
        from: 0,
        count: 50,
        depth: 2,
        depthCount: 5,
      },
    };
    return sendRequest("post", "/article/findArticleComments", params).then(
      (resp) => {
        if (resp.status === "error") {
          throw resp;
        } else {
          return resp;
        }
      }
    );
  };

const articleScoreLoader =
  (sendRequest) =>
  ({ params: { id } }) => {
    const articleId = replaceArticleIdWithDummyId(id);
    const params = { articleId };

    const resolved = (response) => {
      response.data.body.totalScore = (
        response.data.body.totalScore / 100
      ).toFixed(1);

      return {
        status: "ok",
        response,
      };
    };

    return sendRequest(
      "get",
      "/article/getArticleScore",
      params,
      resolved
    ).then((resp) => {
      if (resp.status === "error") {
        throw resp;
      } else {
        return resp;
      }
    });
  };

const reviewRequestsLoader =
  (sendRequest) =>
  ({ params: { id: articleId } }) => {
    const params = { articleId };
    let user = JSON.parse(localStorage.getItem("user"));

    const resolved = (response) => {
      let reviewRequests = response.data.body;
      let isReviewer = false;
      let reviewRequestByCurrentUser = [];

      if (user) {
        reviewRequestByCurrentUser = reviewRequests
          .filter((reviewReq) => reviewReq.reviewerId === user.userId)
          .filter(
            (reviewReq) =>
              reviewReq.reviewRequestStatus == "REVIEW_ACCEPTED" ||
              reviewReq.reviewRequestStatus == "REVIEW_OPEN" ||
              reviewReq.reviewRequestStatus == "REVIEW_IN_PROGRESS"
          );
      }

      if (reviewRequestByCurrentUser.length) {
        isReviewer = true;
        // according to the current backend functionality there may have more than one
        // review request from single article to a single reviewer
        reviewRequestByCurrentUser = reviewRequestByCurrentUser[0];

        response.data.body = { isReviewer, reviewRequestByCurrentUser };
      } else {
        response.data.body = { isReviewer, reviewRequestByCurrentUser: null };
      }

      return {
        status: "ok",
        response,
      };
    };

    return sendRequest(
      "get",
      "/article/findReviewRequestsByArticle",
      params,
      resolved
    ).then((resp) => {
      if (resp.status === "error") {
        throw resp;
      } else {
        return resp;
      }
    });
  };

export const articleLoader = (sendRequest) => async (args) => {
  const articleDetails = articleDetailsLoader(sendRequest)(args);
  const articleComments = articleCommentsLoader(sendRequest)(args);
  const articleScores = articleScoreLoader(sendRequest)(args);
  const reviewRequestDetails = reviewRequestsLoader(sendRequest)(args);

  return defer({
    articleDetails,
    articleComments,
    articleScores,
    reviewRequestDetails,
  });
};
