import React from "react";
import ReviewsInProgressItem from "./ReviewsInProgressItem";
import classes from "./ReviewsInProgressList.module.css";
import SubTitle from "./SubTitle";
import titleIcon from "../../images/reviews-in-progress-title-icon.png";

const ReviewsInProgressList = ({ reviews }) => {
  return (
    <div className={`row ${classes.ReviewsInProgressListContainer}`}>
      <div className="col-12">
        {reviews.reviewsInProgress.map((review) => (
          <ReviewsInProgressItem isReviewCompleted={false} review={review} />
        ))}
        {reviews.reviewsCompleted.map((review) => (
          <ReviewsInProgressItem isReviewCompleted review={review} />
        ))}
      </div>
    </div>
  );
};

export default ReviewsInProgressList;
