import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import SideBarCategoryItem from "../components/home-page-logged-in/SideBarCategoryItem";
import SingleCategoryItem from "../components/home-page-logged-in/SingleCategoryItem";
import SearchResultItem from "../components/SearchResultItem";
import SideBarSimilarProfileItem from "../components/SideBarSimilarProfileItem";
import SimilarProfileItem from "../components/SimilarProfileItem";
import classes from "./CategoryArchive.module.css";
import educationIcon from "../components/images/education-icon.png";
import dataIcon from "../components/images/data-icon.png";
import techIcon from "../components/images/tech-icon.png";
import petIcon from "../components/images/pet-icon.png";
import religionIcon from "../components/images/religion-icon.png";
import fashionIcon from "../components/images/fashion-icon.png";
import { userContext } from "../components/LoggedInBaseLayout";
import useFetchAllSimilarProfiles from "../custom-hooks/useFetchAllSimilarProfiles";

function CategoryArchive() {
  const userDetails = useContext(userContext);
  const { similarProfiles, success: fetchedSimilarProfiles } =
    useFetchAllSimilarProfiles(userDetails?.userId || null);
  const { category_name } = useParams();

  const [articles, setArticles] = useState({
    status: 200,
    articles: [
      {
        id: 123,
        title: "The Role of Cytokines in Cancer Immunotherapy",
        thumbnail_url: "https://picsum.photos/id/54/500",
        score: "100%",
        read_time: 2,
        author: "jenny willson",
        saved: true,
      },
      {
        id: 123,
        title: "The Role of Cytokines in Cancer Immunotherapy",
        thumbnail_url: "https://picsum.photos/id/55/500",
        score: "65%",
        read_time: 2,
        author: "jenny willson",
        saved: false,
      },
      {
        id: 123,
        title: "The Role of Cytokines in Cancer Immunotherapy",
        thumbnail_url: "https://picsum.photos/id/56/500",
        score: "65%",
        read_time: 2,
        author: "jenny willson",
        saved: false,
      },
      {
        id: 123,
        title: "The Role of Cytokines in Cancer Immunotherapy",
        thumbnail_url: "https://picsum.photos/id/57/500",
        score: "65%",
        read_time: 2,
        author: "jenny willson",
        saved: true,
      },
      {
        id: 123,
        title: "The Role of Cytokines in Cancer Immunotherapy",
        thumbnail_url: "https://picsum.photos/id/58/500",
        score: "65%",
        read_time: 2,
        author: "jenny willson",
        saved: true,
      },
      {
        id: 123,
        title: "The Role of Cytokines in Cancer Immunotherapy",
        thumbnail_url: "https://picsum.photos/id/59/500",
        score: "65%",
        read_time: 2,
        author: "jenny willson",
        saved: false,
      },
    ],
  });

  const [categoriesList, setCategoriesList] = useState([
    {
      title: "education",
      icon: educationIcon,
    },
    {
      title: "data",
      icon: dataIcon,
    },
    {
      title: "tech",
      icon: techIcon,
    },
    {
      title: "pets",
      icon: petIcon,
    },
    {
      title: "religion",
      icon: religionIcon,
    },
    {
      title: "fashion",
      icon: fashionIcon,
    },
  ]);

  return (
    <div className={`container customContainer ${classes.mainContainer}`}>
      <div className="row">
        <div className={`col-xxl-9 col-12 ${classes.leftContainer}`}>
          <div className={classes.searchResultsHeadingContainer}>
            <p className={classes.searchResultsHeading}>{category_name}</p>
          </div>
          <div className={`row ${classes.articlesContainer}`}>
            {articles.articles.map((article, key) => (
              <SearchResultItem {...article} key={key} />
            ))}
          </div>
          <div
            className={`d-xxl-none d-block ${classes.mobileSearchProfileContainer}`}
          >
            <p className={classes.profileSearchResultsTitle}>
              Profiles with similar interests
            </p>
            {fetchedSimilarProfiles && (
              <div className="row">
                {similarProfiles.slice(0, 4).map((profile, key) => (
                  <SimilarProfileItem
                    key={key}
                    name={`${profile.firstName} ${profile.lastName}`}
                    // TODO: about is missing in the current response.Check later if it's available
                    description={profile.about}
                    profileImage={profile.profileImage}
                    username={profile.loginName}
                    userId={profile.userId}
                  />
                ))}
              </div>
            )}
          </div>
          <div
            className={`d-xxl-none d-block ${classes.mobileInterestCategoriesdiv}`}
          >
            <p className={classes.interestCategoriesTitle}>
              Categories you may interest
            </p>
            <div className="row">
              {categoriesList.map((category, key) => (
                <SingleCategoryItem
                  title={category.title}
                  icon={category.icon}
                  key={key}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={`col-xxl d-xxl-block d-none ${classes.rightContainer}`}>
          <section>
            <p className={classes.interestCategoriesTitle}>
              Categories you may interest
            </p>
            <div className={`row ${classes.listContainer}`}>
              {categoriesList.map((item, key) => (
                <SideBarCategoryItem category={item.title} key={key} />
              ))}
            </div>
          </section>
          {fetchedSimilarProfiles && (
            <section>
              <p className={classes.profileSearchResultsTitle}>
                Profiles with similar interests
              </p>
              {similarProfiles.slice(0, 5).map((profile, key) => (
                <SideBarSimilarProfileItem
                  key={key}
                  name={`${profile.firstName} ${profile.lastName}`}
                  username={profile.loginName}
                  // TODO: jobTitle is missing in the current response.Check later if it's available
                  jobTitle={profile.jobTitle}
                  profileImage={profile.profileImage}
                  userId={profile.userId}
                />
              ))}
            </section>
          )}
        </div>
      </div>
    </div>
  );
}

export default CategoryArchive;
