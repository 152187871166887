import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./SingleReviewer.module.css";

const SingleReviewer = ({ userName, userImage }) => {
  return (
    <div className={`col-auto ${classes.itemContainer}`}>
      <NavLink to={`/user/${userName}`}>
        <div
          className={`${classes.imageContainer}`}
          style={{ backgroundImage: `url(${userImage})` }}
        ></div>
      </NavLink>
    </div>
  );
};

export default SingleReviewer;
