import React from "react";
import { Link } from "react-router-dom";
import classes from "./TopCategoryItem.module.css";

const TopCategoryItem = ({ categoryName }) => {
  return (
    <div className="d-flex">
      <Link to={`/category/${categoryName}`} style={{ textDecoration: "none" }}>
        <p className={`${classes.listItem}`}>{categoryName}</p>
      </Link>
    </div>
  );
};

export default TopCategoryItem;
