import React, { useEffect, useState } from "react";
import { createTwoFilesPatch } from "diff";
import * as Diff2Html from "diff2html";
import "diff2html/bundles/css/diff2html.min.css";
import classes from "./CustomDiffComponent.css";
import DiffComponentTitle from "./DiffComponentTitle";

function DiffComponent({ prevText, newText, title, type }) {
  const [outputHtml, setOutputHtml] = useState("");

  useEffect(() => {
    init();
  }, [prevText, newText]);

  const init = () => {
    let strInput = createTwoFilesPatch(type, type, prevText, newText);
    let outputHtml = Diff2Html.html(strInput, {
      drawFileList: true,
      fileListToggle: false,
      fileContentToggle: false,
      matching: "lines",
      // outputFormat: "side-by-side",
      synchronisedScroll: true,
      highlight: true,
      renderNothingWhenEmpty: true,
    });
    setOutputHtml(outputHtml);
  };
  return (
    <>
      <DiffComponentTitle title={title} />
      <div
        className={`diffContainer`}
        dangerouslySetInnerHTML={{ __html: outputHtml }}
      />
    </>
  );
}

export default DiffComponent;
