import React from "react";
import classes from "./PageCloseButton.module.css";
import { useNavigate } from "react-router-dom";

function PageCloseButton() {
  const navigate = useNavigate();

  return (
    <div className={`container-fluid`}>
      <div className="row justify-content-end">
        <div className="col-auto">
          <div className={classes.closeButtonContainer}>
            <span
              onClick={() => navigate("/dashboard/my-articles")}
              className={`material-icons-outlined ${classes.closeButton}`}
            >
              close
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageCloseButton;
