import React, { useContext, useEffect, useState } from "react";
import DashboardMobileWriteArticleButton from "../components/dashboard/DashboardMobileWriteArticleButton";
import DashboardTabTitle from "../components/dashboard/DashboardTabTitle";
import SingleArticleItem from "../components/dashboard/SingleArticleItem";
import InviteModal from "../components/InviteModal";
import myArticlesIcon from "./images/my-articles.png";
import classes from "./MyArticles.module.css";
import { defer, useLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import { userContext } from "../components/LoggedInBaseLayout";
import useFetchAllSimilarProfiles from "../custom-hooks/useFetchAllSimilarProfiles";
import RequestProgressBar from "../components/RequestProgressBar";
import EmptyArticlesListMessage from "../components/EmptyArticlesListMessage";
import DashboardMessageContainer from "../components/DashboardMessageContainer";

export default function MyArticles() {
  const loader = useLoaderData();
  const [startProgressBar, setStartProgressBar] = useState(true);
  const [completeProgressBar, setCompleteProgressBar] = useState(false);
  const [articlesList, setArticlesList] = useState(null);
  const [inviteModalShow, setInviteModalShow] = useState(false);
  const [articleDetails, setArticleDetails] = useState(null);
  const [errorLoadingArticles, setErrorLoadingArticles] = useState(false);
  const userDetails = useContext(userContext);
  const { similarProfiles, success: fetchedSimilarProfiles } =
    useFetchAllSimilarProfiles(userDetails?.userId || null);

  useEffect(() => {
    const { articlesLoader } = loader;

    articlesLoader
      .then(({ response }) => {
        setArticlesList(response.data.body.articleResponseList);
      })
      .catch(({ error }) => {
        setErrorLoadingArticles(true);
        toast.error(
          error?.response?.data.message || error || "Faild to load articles!"
        );
      })
      .finally(() => {
        setCompleteProgressBar(true);
      });
  }, [loader]);

  return (
    <>
      <RequestProgressBar
        continuousStart={startProgressBar}
        complete={completeProgressBar}
      />
      <InviteModal
        show={inviteModalShow}
        onHide={() => setInviteModalShow(false)}
        articleDetails={articleDetails}
        similarProfiles={similarProfiles}
        fetchedSimilarProfiles={fetchedSimilarProfiles}
      />
      <DashboardTabTitle
        icon={myArticlesIcon}
        iconWidth="38px"
        iconHeight="38px"
        tabName="my articles"
      />
      {articlesList &&
        (articlesList.length ? (
          articlesList.map((article, key) => (
            <SingleArticleItem
              key={key}
              article={article}
              articlesList={articlesList}
              setArticlesList={setArticlesList}
              setInviteModalShow={() => setInviteModalShow(true)}
              setArticleDetails={setArticleDetails}
            />
          ))
        ) : (
          <EmptyArticlesListMessage />
        ))}
      {/* show error message */}
      {errorLoadingArticles && <DashboardMessageContainer status="error" />}
      <DashboardMobileWriteArticleButton />
    </>
  );
}

export const myArticlesLoader = (findArticlesByUser) => async () => {
  let user = JSON.parse(localStorage.getItem("user"));
  let userId = user?.userId || null;

  const articlesLoader = findArticlesByUser(userId, true).then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });

  return defer({ articlesLoader });
};
