import React from "react";

const NavBarMenuIcon = () => {
  return (
    <span style={{ color: "black", fontSize: "30px" }} className="material-icons">
      menu
    </span>
  );
};

export default NavBarMenuIcon;
