import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavigationBarLinkComponent.module.css";

const NavigationBarLinkComponent = (props) => {
  return (
    <NavLink
      end
      style={{ textDecoration: "none" }}
      to={props.path}
      className={({ isActive }) => (isActive ? `${classes.activeTab}` : "")}
      onClick={props.onClickHandle}
    >
      <p className={`${classes.navText} ${props.overrideNavTextStyle}`}>
        {props.tabName}
      </p>
    </NavLink>
  );
};

export default NavigationBarLinkComponent;
