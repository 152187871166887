import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./SingleArticleItem.module.css";

const SingleArticleItem = (props) => {
  return (
    <div className={`col-lg-3 col-sm-6 col-12 ${classes.articleContainer}`}>
      <NavLink
        to={`/article/${props.id}/${props.title.split(" ").join("_")}`}
        style={{ textDecoration: "none" }}
      >
        <div
          className={`${classes.articleImage}`}
          style={{
            backgroundImage: `url(https://picsum.photos/id/${props.id}/500)`,
          }}
        ></div>
      </NavLink>
      <NavLink
        to={`/article/${props.id}/${props.title.split(" ").join("_")}`}
        style={{ textDecoration: "none" }}
      >
        <p className={`${classes.articleTitle}`}>{props.title}</p>
      </NavLink>
      <p className={`${classes.articleContent}`}>{props.content}</p>
      <div className="d-flex">
        <NavLink to="/user/john_doe" style={{ textDecoration: "none" }}>
          <p className={`${classes.articleAuthor}`}>{props.author}</p>
        </NavLink>
      </div>
    </div>
  );
};

export default SingleArticleItem;
