import React, { useContext, useEffect, useState } from "react";
import titleIcon from "./images/edit-profile-title-icon.png";
import classes from "./EditProfileDetails.module.css";
import imageUploadIcon from "./images/edit-profile-image-upload-icon.png";
import EditProfileInputContainer from "../components/EditProfileInputContainer";
import { userContext } from "../components/LoggedInBaseLayout";
import countyData from "../countryList.json";
import EditProfileSelectContainer from "../components/EditProfileSelectContainer";
import { Formik, Form } from "formik";
import { useActionData, useNavigation, useSubmit } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import EditProfileTextAreaContainer from "../components/EditProfileTextAreaContainer";
import { BeatLoader } from "react-spinners";
import defaultAvatar from "../components/images/avator.png";

export default function EditProfileDetails() {
  const [profileImage, setProfileImage] = useState(defaultAvatar);
  const userDetails = useContext(userContext);
  const countries = countyData.countries;
  const submit = useSubmit();
  const actionData = useActionData();
  const navigation = useNavigation();

  const experienceYears = [
    { key: "5+ years", value: "5+ years" },
    { key: "4 years", value: "4 years" },
    { key: "3 years", value: "3 years" },
    { key: "2 years", value: "2 years" },
    { key: "1 year", value: "1 year" },
  ];

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    jobTitle: "",
    company: "",
    experience: "",
    website: "",
    description: "",
    profileImage: "",
  });

  // yup url validation
  const yupUrlRegex =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

  const validationSchema = Yup.object({
    country: Yup.string().required("This field is required!"),
    jobTitle: Yup.string().required("This field is required!"),
    experience: Yup.string().required("This field is required!"),
    description: Yup.string().required("This field is required!"),
    website: Yup.string().matches(yupUrlRegex, "Invalid URL!"),
  });

  // re-initialize the form values when the userDetails is available
  useEffect(() => {
    if (userDetails) {
      const newInitialValues = {
        firstName: userDetails?.firstName || "",
        lastName: userDetails?.lastName || "",
        email: userDetails?.email || "",
        country: userDetails?.country || "",
        jobTitle: userDetails?.jobTitle || "",
        company: userDetails?.company || "",
        experience: userDetails?.experience || "",
        website: userDetails?.websiteURL || "",
        description: userDetails?.description || "",
        profileImage: userDetails?.profileImage || "",
      };

      setInitialValues(newInitialValues);
      if (userDetails.profileImage) {
        setProfileImage(userDetails.profileImage);
      }
    }
  }, [userDetails]);

  const onSubmit = (values) => {
    // hide all toast notifications
    toast.dismiss();
    submit(
      { loginName: userDetails.loginName, ...values },
      { method: "post", action: "/profile/edit/details" }
    );
  };

  // triggers once the response is recieved from the backend
  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        toast.success(
          actionData.response.data.message || "Profile updated successfully!"
        );
      } else {
        toast.error(
          actionData.error.response.data.message || "Faild to update profile!"
        );
      }
    }
  }, [actionData]);

  return (
    <div className="col-12">
      <div className="row justify-content-center">
        <div className={`col-xxl-auto col-xl-7 col-lg-8 col-12`}>
          <div className={`${classes.editProfileContainer}`}>
            <div className={`d-flex ${classes.titleContainer}`}>
              <div className={`d-flex ${classes.titleIconContainer}`}>
                <img
                  className={`m-auto ${classes.titleIcon}`}
                  src={titleIcon}
                  alt="icon"
                />
              </div>
              <div className="d-flex">
                <h1 className={`m-auto ${classes.title}`}>Edit Profile</h1>
              </div>
            </div>
            <div className={`${classes.profileImageContainer}`}>
              <div
                className={`${classes.profileImage}`}
                style={{
                  backgroundImage: `url(${profileImage})`,
                }}
              >
                <img
                  className={`${classes.imageUploadIcon}`}
                  src={imageUploadIcon}
                  alt="icon"
                />
              </div>
            </div>
            <div className={`${classes.formContainer}`}>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize
              >
                <Form>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <EditProfileInputContainer
                        label="first name"
                        name="firstName"
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <EditProfileInputContainer
                        label="last name"
                        name="lastName"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <EditProfileInputContainer
                        label="email"
                        name="email"
                        disabled={true}
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <EditProfileSelectContainer
                        options={countries}
                        name="country"
                        label="country"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <EditProfileInputContainer
                        label="job title"
                        name="jobTitle"
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <EditProfileSelectContainer
                        options={experienceYears}
                        name="experience"
                        label="experience"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <EditProfileInputContainer
                        label="website"
                        name="website"
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <EditProfileInputContainer
                        label="company name"
                        name="company"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p className={classes.inputLabel}>about</p>
                      <EditProfileTextAreaContainer name="description" />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-sm-auto col-12">
                      <button
                        type="submit"
                        className={classes.formSubmitButton}
                      >
                        {navigation.state === "idle" ? (
                          "submit"
                        ) : (
                          <BeatLoader loading size={10} />
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const editProfileAction =
  (sendRequest) =>
  async ({ request }) => {
    const formData = await request.formData();
    const {
      firstName,
      lastName,
      loginName,
      country,
      jobTitle,
      company,
      experience,
      website: websiteURL,
      description,
      profileImage,
    } = Object.fromEntries(formData);

    const user = JSON.parse(localStorage.getItem("user"));
    const data = {
      body: {
        userId: user?.userId || null,
        firstName,
        lastName,
        loginName,
        country,
        jobTitle,
        company,
        experience,
        websiteURL,
        description,
        profileImage,
      },
    };

    return sendRequest("post", "/user/addUserInfo", data);
  };
