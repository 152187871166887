import classes from "./PrivacyPolicy.module.css";

export default function PrivacyPolicy() {
  return (
    <div className="container customContainer">
      <div className="row justify-content-center">
        <div className="col-lg-auto col-12">
          <div className={`${classes.centerDiv}`}>
            <div className={classes.titleContainer}>
              <h1 className={classes.title}>PUPLIER PRIVACY POLICY</h1>
            </div>
            <h2>ABOUT OUR PRIVACY AND COOKIES POLICY:</h2>
            <p>
              Authcite Limited and its affiliates control the data provided to
              it by our panellists and those who otherwise participate in
              surveys, use our online platforms or browse our website (“user”).
              If you are a user or panellist who registered with us puplier.com
              (referred to as “we”, “us”, “our”, or “Puplier”) is the controller
              of your personal data. This means we choose why and how that data
              is processed. We are based in United Kingdom.
            </p>
            <p>
              Please note that we are in Alfa mode and we are launching our
              functionality for the first time. This means our application and
              software might have bugs and issues yet to resolve and might
              behave in unexpected ways. If you are not sure about using our
              software under these conditions, please refrain from using the
              puplier platform.
            </p>
            <h4>How to contact us</h4>
            <p>
              Your opinion matters to us – if you have any questions about our
              privacy policy or your privacy settings, please submit your query
              and a member of our dedicated team will respond to you. If you
              would like to email us please send it marked the “Privacy Team” to{" "}
              <a className={classes.email} href="mailto:hello@puplier.com">
                hello@puplier.com
              </a>
            </p>
            <h2>PERSONAL INFORMATION WE COLLECT ABOUT YOU:</h2>
            <p>
              When you interact with our website or take part in our surveys,
              you provide us with the data that powers our business. While there
              are certain data that you must provide to use some of our
              services, you control how much data you share with us.
            </p>
            <p>
              We will only retain your personal data for as long as we need to
              in order to fulfil the uses we describe in this notice (or until
              you exercise your right to erasure, which is explained in the
              section about your rights ).
            </p>
            <h4>When you register with us we collect:</h4>
            <p>
              Contact details such as your name, email, demographic data and
              general economic and household information, your account login
              details, which will be your email address and the password that
              you have chosen
            </p>
            <h4>
              When you answer questions in surveys, or on our website or online
              platforms, we collect:
            </h4>
            <ul>
              <li>
                Your opinion on published articles and data generated by your
                actions such as voting or reviewing articles.
              </li>
              <li>
                Your opinions on platform functionality, usage and issues, and
                in respect of a particular article, researcher or university or
                other organisation or person.
              </li>
            </ul>
            <h4>
              When you browse our website or use our online platforms, we
              collect:
            </h4>
            <ul>
              <li>
                Information through the use of cookies and similar technologies
              </li>
            </ul>
            <h4>
              When you contact us or interact with our website or social media
              pages, we collect:
            </h4>
            <ul>
              <li>
                Any personal data that you may you provide about yourself,
                including your name and
              </li>
              <li>
                contact details and the conversation history of our
                communications with you
              </li>
              <li>
                Any comments you make on our website or social media pages, and
              </li>
              <li>
                whether you have chosen to ‘follow’ any other member on our
                website
              </li>
            </ul>
            <h2>SPECIAL CATEGORIES OF PERSONAL DATA:</h2>
            <p>
              Some of the data that you provide us will fall within what EU Data
              protection law describes as ‘special categories of personal data’,
              such as your racial or ethnic origin, political opinions,
              religious beliefs or information concerning your health or sexual
              orientation. We use this information in the same way we use all
              the other information we collect, but the law requires that we
              have your explicit consent to collect and use it. You will be
              explicitly asked to consent using those categories of data if you
              register an account with us. Each time we ask a question that
              falls into one of these categories, if you haven’t consented to
              our use of data in that category we’ll remind you that we need it.
              You will always have the option to skip these questions if you
              would prefer not to allow us to use that category of data, and you
              can always change your consent settings on your account page .
              From the moment you withdraw your consent for any category we will
              make no further use of any data you have shared in that category
              when producing research or providing services to our clients,
              unless and until you choose to re-consent.
            </p>
            <h2>INTERNATIONAL DATA TRANSFERS</h2>
            <p>
              We may need to transfer your information to other service
              providers in countries outside the UK, such as US and countries in
              the European Economic Area (EEA).
            </p>
            <p>
              The EEA consists of countries in the European Union, Switzerland,
              Iceland, Liechtenstein and Norway. This kind of data transfer may
              happen if our servers (i.e. where we store data) or our suppliers
              and service providers are based outside the UK, or if you use our
              services and products while visiting countries outside the UK.
            </p>
            <p>
              If we send your information to a country that is not in the UK, we
              will make sure that your information is properly protected. We
              will always ensure that there is a proper legal agreement that
              covers the data transfer. In addition, if the country is not
              considered to have laws that are equivalent to UK data protection
              standards then we will ask the third party to enter into a legal
              agreement that reflects those standards.
            </p>
            <h2>KEEPING YOUR PERSONAL INFORMATION SECURE:</h2>
            <p>
              We have specialised security teams who constantly review and
              improve our measures to protect your personal information from
              unauthorised access, accidental loss, disclosure or destruction.
              communications over the internet (such as emails) aren’t secure
              unless they’ve been encrypted. Your communications may go through
              a number of countries before being delivered, as this is the
              nature of the internet.
            </p>
            <p>
              We cannot accept responsibility for any unauthorised access or
              loss of personal information that is beyond our control. We’ll
              never ask for your secure personal or account information by an
              unsolicited means of communication. You’re responsible for keeping
              your personal and account information secure and not sharing it
              with others. Our website may provide links to third-party
              websites. We cannot be responsible for the security and content of
              such third-party websites. So make sure you read that company’s
              privacy and cookies policies before using or putting your personal
              information on their site. The same applies to any third-party
              websites or content you connect to using our products and
              services. You may choose to disclose your information in certain
              ways such as social plug-ins (including those offered by Google,
              Facebook, Twitter and Pinterest) or using third-party services
              that allow you to post reviews or other information publicly, and
              a third party could use that information.
            </p>
            <p>
              Social plug-ins and social applications are operated by the social
              network themselves and are subject to their own terms of use and
              privacy and cookies policies. You should make sure you’re familiar
              with these.
            </p>
            <h2>HOW WE STORE AND PROTECT YOUR PERSONAL DATA:</h2>
            <p>
              We do everything we can to protect your personal data from loss or
              misuse, and from unauthorised access, disclosure, alteration and
              destruction. This section describes some of the measures we take
              to ensure that your personal data is secure:
            </p>
            <p>
              We use data centres that have a high level of physical security
              measures to host and protect your data and our systems; if you
              ever think that you have found a security issue or vulnerability
              in one of our systems please let us know at{" "}
              <a className={classes.email} href="mailto:hello@puplier.com">
                hello@puplier.com
              </a>
            </p>
            <p>
              We use encryption to secure your personal data whilst it is in
              transit using TLS and in storage using………..encryption;
            </p>
            <p>
              Our website may from time to time contain links to and from other
              websites. If you follow a link to any of those websites, please
              note that those websites ought to have their own privacy notices
              and that we do not accept any responsibility or liability for
              those websites. Please check those privacy notices before you
              submit your information to those websites.
            </p>
            <h2>EMAILS AND NOTIFICATIONS</h2>
            <p>
              If you register an account, it is integral to your participation
              that we are able to send you emails and notifications inviting you
              to participate in research projects, which could be conducted
              online via survey. If you would prefer not to receive these
              service messages or notifications you will need to withdraw from
              the platform entirely.
            </p>
            <p>
              Separately, we may want to send you emails and notifications when
              your opinions feature in the news, or to encourage you to refer
              your friends and family to join too. If you would prefer not to
              receive these messages or notifications you will need to withdraw
              from the platform entirely.
            </p>

            <h2>COOKIES AND SIMILAR TECHNOLOGIES</h2>
            <p>
              We use “cookies” to improve your experience as you use our website
              and mobile apps. This section tells you about the cookies that we
              use, what they do and your choices when it comes to cookies.
            </p>
            <h4>Partner cookies</h4>
            <p>
              These cookies allow our partners to combine your survey data with
              their own data using common online identifiers. Our partners use
              this data to create and improve their products and services, and
              to create an audience for advertisers to target with more relevant
              communications and propositions.
            </p>
            <h4>Your choices when it comes to cookies</h4>
            <p>
              When you accessed this website you were presented with an alert
              that notified you of our use of cookies. If you continue to use
              the website you are agreeing to the use of the cookies described
              in this notice. However, you can use your browser settings to
              accept or reject new cookies and to delete existing cookies. The
              ‘Help’ function within your browser should tell you how, and you
              can find more about how you can delete and manage cookies at{" "}
              <a
                className={classes.link}
                href="http://www.allaboutcookies.org/"
                target="__blank"
              >
                http://www.allaboutcookies.org/
              </a>
              . If you are primarily concerned about third party advertising
              cookies, you can turn these off by going to Your Online Choices at{" "}
              <a
                className={classes.link}
                href="http://www.youronlinechoices.com/uk/your-ad-choices"
                target="__blank"
              >
                http://www.youronlinechoices.com/uk/your-ad-choices
              </a>
              . You should be aware that restricting the use of cookies may mean
              that you will not be able to take full advantage of all the
              features or services available on this website.
            </p>
            <h2>YOUR RIGHTS</h2>
            <p>
              You have certain rights in relation to the personal data that we
              hold about you, which are designed to give you more choice and
              control over your personal data. These rights are explained below.
            </p>
            <ul>
              <li>
                The right to request access to personal data: You can request a
                copy of the data we hold about you and related information.
              </li>
              <li>
                The right to request rectification of personal data: You can ask
                us to correct any inaccurate data about you and to complete any
                incomplete data that we hold about you.
              </li>
              <li>
                The right to request erasure of personal data: You can request
                that we delete the personal data we hold about you in certain
                situations.
              </li>
              <li>
                The right to request a restriction on processing of personal
                data: You can request that we restrict our use of your data to
                storage only, that we stop using it for all other purposes or
                that we retain data that was due for deletion in certain
                situations.
              </li>
              <li>
                The right to object to the processing of personal data: You can
                object to certain types of processing of your personal data in
                certain specific circumstances in certain situations
              </li>
            </ul>
            <h2>EXERCISING YOUR RIGHTS:</h2>
            <p>
              You can exercise any of these rights by using the contact details
              below. Once you have submitted your request we may contact you to
              request further information to authenticate your identity (because
              we want to make sure it is actually you requesting your data) or
              to help us to respond to your request. Except in rare cases, we
              will respond to you within 1 month of receiving this information
              or, where no such information is required, after we have received
              full details of your request. As noted above, while some rights
              apply generally, some are only available in certain circumstances,
              so if we feel that any right is not available to you we will let
              you know along with the reason for our decision.
            </p>
            <p>
              Email:{" "}
              <a className={classes.email} href="mailto:hello@puplier.com">
                hello@puplier.com
              </a>{" "}
              with the title - To the attention of Privacy/Data Protection
              Officer.
            </p>
            <h4>Lodging a complaint with a regulator</h4>
            <p>
              You have the right to lodge a complaint with a data protection
              regulator in Europe, in particular in a country you work or live,
              where your legal rights have been infringed or where your personal
              information has or is being used in a way that you believe does
              not comply with data. The contact details for the Information
              Commissioner’s Office (“ICO”), the UK’s independent regulatory
              body that upholds information rights, are available on their
              website, which also contains details on how to make a complaint .
              However, we encourage you to contact us before making any
              complaint and we will seek to resolve any issues or concerns you
              may have.
            </p>
            <h4>How to contact us</h4>
            <p>
              If you have questions about this notice, or about how we collect,
              store and use personal data, you can contact our Data Protection
              Officer.
            </p>
            <p>
              Email:{" "}
              <a className={classes.email} href="mailto:hello@puplier.com">
                hello@puplier.com
              </a>{" "}
              with the title - To the attention of Privacy/Data Protection
              Officer.
            </p>
            <h2>UPDATE TO THIS NOTICE :</h2>
            <p>
              This notice was last updated on the date that appears at the
              beginning of the notice. While we reserve the right to change this
              notice at any time, if any material changes are made we will
              provide notice to you via email or any other appropriate means to
              give you the opportunity to review the changes before they become
              effective. If you object to any future changes, you may close your
              account by emailing us at{" "}
              <a className={classes.email} href="mailto:hello@puplier.com">
                hello@puplier.com
              </a>{" "}
              with the title - Account Closure Request.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
