import React, { useState } from "react";
import SingleArticleItem from "./SingleArticleItem";

const TopArticleListContainer = () => {
  const [articlesList, setArticlesList] = useState([
    {
      id: 159,
      title: "The Role of Cytokines in Cancer Immunotherapy",
      content:
        "This article presents a comprehensive overview of the current state of research in...",
      author: "Jenny Wilson",
    },
    {
      id: 160,
      title: "The Dynamics of Climate Change",
      content:
        "This article presents a comprehensive overview of the current state of research in...",
      author: "Cody Fisher",
    },
    {
      id: 161,
      title: "The Role of Microbes in Human Health",
      content:
        "This article presents a comprehensive overview of the current state of research in...",
      author: "Robert Fox",
    },
    {
      id: 162,
      title: "The Role of Pollution in Biodiversity Loss",
      content:
        "This article presents a comprehensive overview of the current state of research in...",
      author: "Jenny Wilson",
    },
  ]);

  return (
    <div className="row">
      {articlesList.map((item, key) => (
        <SingleArticleItem
          key={key}
          id={item.id}
          title={item.title}
          content={item.content}
          author={item.author}
        />
      ))}
    </div>
  );
};

export default TopArticleListContainer;
